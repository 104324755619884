import { graphql } from "gatsby";
import PropTypes from "prop-types";
import * as React from "react";
import styled from "styled-components";
import InternalLink from "components/InternalLink";

import { Layout, PostCard } from "../components";
import { MetaData } from "../../../components/meta";

const PostFeed = styled.div`
    position: relative;
    display: grid;
    gap: 4.8vmin 4vmin;
    grid-template-columns: repeat(6, 1fr);
    padding: max(4.8vmin, 36px) 0 0;
    margin: 4vmin auto;
    max-width: 1200px;
    width: 100%;

    @media (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1001px) {
        .post-card {
            &:nth-child(2),
            &:nth-child(3) {
                grid-column: span 2;
                .post-card-title {
                    font-size: 2.6rem;
                }
            }
        }
    }
`;

const PostCardContent = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const PostCardContentLink = styled.div`
    position: relative;
    display: block;
    color: var(--color-darkgrey);
`;

const AuthorProfilePic = styled.img`
    display: block;
    width: 80px;
    height: 80px;
    -o-object-fit: cover;
    object-fit: cover;
    margin: 0 0 2rem;
    border-radius: 50%;
    background-color: #e6e6e6;
`;

const PostCardTitle = styled.h2`
    margin: 0;
    font-size: 2.6rem;
    font-weight: 800;
    @media (min-width: 1001px) {
        margin-top: 0;
        font-size: 4.4rem;
        line-height: 1;
    }
`;

const PostCardExcerpt = styled.div`
    display: -webkit-box;
    overflow-y: hidden;
    margin-top: 12px;
    max-width: 720px;
    font-size: 1.6rem;
    line-height: 1.5;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
`;

const AuthorProfileFooter = styled.footer`
    margin-top: 16px;
`;

const AuthorProfileLocation = styled.div`
    font-weight: 700;
`;

const AuthorProfileMeta = styled.div`
    display: flex;
    gap: 10px;
`;

const AuthorProfileSocialLink = styled(InternalLink)`
    font-size: 1.3rem;
    color: var(--color-secondary-text);
    &:hover {
        color: var(--color-darkgrey);
    }
    svg {
        width: 16px;
        height: 16px;
    }
`;

/**
 * Author page (/author/:slug)
 *
 * Loads all posts for the requested author incl. pagination.
 *
 * @param root0
 * @param root0.data
 * @param root0.location
 */
const Author = ({ data, location }) => {
    const author = data.ghostAuthor;
    const posts = data.allGhostPost.edges;

    return (
        <>
            <MetaData data={data} location={location} type="profile" />
            <Layout>
                <main className="site-main">
                    <PostFeed>
                        <section className="post-card post-card-large">
                            <PostCardContent>
                                <PostCardContentLink>
                                    <AuthorProfilePic
                                        src={
                                            author.profile_image ||
                                            "/icons/avatar.svg"
                                        }
                                        alt={author.name}
                                        sizes="(max-width: 1000px) 400px, 800px"
                                    />

                                    <PostCardTitle>{author.name}</PostCardTitle>
                                    {author.bio && (
                                        <PostCardExcerpt>
                                            {author.bio}
                                        </PostCardExcerpt>
                                    )}
                                    <AuthorProfileFooter>
                                        {author.location && (
                                            <AuthorProfileLocation>
                                                {author.location}
                                            </AuthorProfileLocation>
                                        )}
                                        <AuthorProfileMeta>
                                            {[
                                                "website",
                                                "twitter",
                                                "facebook",
                                            ].map(
                                                (media) =>
                                                    author[media] && (
                                                        <AuthorProfileSocialLink
                                                            key={media}
                                                            to={
                                                                author[media] ||
                                                                "/"
                                                            }
                                                            target="_blank"
                                                            rel="noopener"
                                                        >
                                                            {author[media]}
                                                        </AuthorProfileSocialLink>
                                                    )
                                            )}
                                        </AuthorProfileMeta>
                                    </AuthorProfileFooter>
                                </PostCardContentLink>
                            </PostCardContent>
                        </section>
                        {posts.map(({ node }) => (
                            <PostCard key={node.id} post={node} />
                        ))}
                    </PostFeed>
                </main>
            </Layout>
        </>
    );
};

Author.propTypes = {
    data: PropTypes.shape({
        ghostAuthor: PropTypes.shape({
            name: PropTypes.string.isRequired,
            cover_image: PropTypes.string,
            profile_image: PropTypes.string,
            website: PropTypes.string,
            bio: PropTypes.string,
            location: PropTypes.string,
            facebook: PropTypes.string,
            twitter: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default Author;

export const pageQuery = graphql`
    query GhostAuthorQuery($slug: String!) {
        ghostAuthor(slug: { eq: $slug }) {
            ...GhostAuthorFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
